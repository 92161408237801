import {createRouter, createWebHistory} from 'vue-router'
import {isLocalLogin} from "@/common/utils";
import {getRouterList} from "@/router/dynamic";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index_view.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login_view.vue'),
        meta: {
            requiredAuth: false
        }
    },
    {
        path: '/admin',
        name: 'AdminView',
        component: () => import('../views/admin_view.vue'),
        meta: {
            requiredAuth: true
        },
        children: []
    },
    {
        path: '/data/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/dashboard_view.vue'),
        children: []
    },
    {
        path: '/data/dashboard/six_service',
        name: 'SixService',
        component: () => import('../views/dashboard/dashboard_six_service_view.vue'),
    },
    {
        path: '/data/dashboard/performance',
        name: 'Performance',
        component: () => import('../views/dashboard/dashboard_performance_view.vue'),
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

let loadRouter = 0

// 登录拦截
router.beforeEach((to, from, next) => {
    try {
        if (loadRouter === 0) {
            getRouterList()

            loadRouter = 1
            if (to.matched.length === 0 || to.meta.requiredAuth) {
                next({path: to.path});
            } else {
                next();
            }
        }

        if (to.meta.requiredAuth) {
            if (!isLocalLogin()) {
                next({
                    path: '/login'
                })
            } else {
                next()
            }
        } else {
            next()
        }
    } catch (error) {
        console.log("router error", error)
        next({
            path: '/login'
        })
    }

})

export default router
