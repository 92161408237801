// 服务器地址
// export let baseUrl = "http://127.0.0.1:10200/"
export let baseUrl = "https://qingke.yunshuhui.com/"
// export let baseUrl = "http://localhost:10200/"

export let SUCCESS_CODE = 100000;
export let NEED_LOGIN = 100099;

export let defaultPage = {
    index: 1,
    size: 20,
}
