import mitt from 'mitt'

const emitter = mitt()
const tabRouterChangeKey = "tab-router-change"
const closeRouterTabKey = "close-router-tab"
const selectedRouterTabKey = "selected-router-tab"

const reloadSideRouterKey = "reload-side-router"

const roleLoadSuccessKey = "role-load-success"

const showUserInfoKey = "show-user-info"
const logoutKey = "logout"

export {
    emitter,
    tabRouterChangeKey,
    closeRouterTabKey,
    selectedRouterTabKey,
    reloadSideRouterKey,
    roleLoadSuccessKey,
    showUserInfoKey,
    logoutKey,
}
